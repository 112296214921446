/* eslint-disable */
import HttpService from '../HttpService'

export default class AttachmentTypeService {
    constructor() {
        this._httpService = new HttpService(`healthcare`);
    }

    async findAllByUser(userId){
        return await this._httpService.get(`/user_health_challenge/findAllByUser/${userId}`);
    };

    async saveAll(data){
        return await this._httpService.post('/user_health_challenge/saveAllUserHealthChallenge',data)
    }
}
