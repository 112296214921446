<template>
  <div class="avatar-upload-component">
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Arraste aqui para atualizar!</h3>
    </div>
    <div>
      <label for="avatar">
        <v-badge
          bottom
          color="#532E88"
          icon="fas fa-camera"
          overlap
          offset-x="25"
          offset-y="25"
        >
          <v-avatar :size="size" style="overflow: visible">
            <img :src="getPicture"/>
          </v-avatar>
        </v-badge>
      </label>
      <file-upload
        extensions="gif,jpg,jpeg,png,webp"
        accept="image/png,image/gif,image/jpeg,image/webp"
        name="avatar"
        :drop="!edit"
        v-model="files"
        @input-filter="inputFilter"
        @input-file="inputFile"
        ref="upload"
        v-show="false"
        :custom-action="customAction"
      />
    </div>
    <v-card class="upload-page" v-if="files.length && edit">
      <v-card-text class="pa-0">
        <img ref="editImage" :src="files[0].url" style="height: 85vh; width: 100vw;" />
      </v-card-text>
      <v-card-actions>
        <v-row class="pt-5" align="center" justify="space-around">
          <v-col align="center" class="pa-0 pl-6" cols="6">
            <v-btn class="att-btn" color="primary" @click.prevent="editSave">Atualizar</v-btn>
          </v-col>
          <v-col align="center" class="pa-0 pr-6" cols="6">
            <v-btn class="cancel-btn" color="secondary" @click.prevent="cancel()">Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import Cropper from 'cropperjs'
  import FileUpload from 'vue-upload-component'
  import $auth from "../../common/auth";
  import {updateProfilePicture} from "../../common/securityserver";

  export default {
    components: {
      FileUpload,
    },

    props:{
      profilePictureSrc: null,
      size: {
        default: "60",
        type: String,
      }
    },

    data() {
      return {
        files: [],
        edit: false,
        cropper: false,
      }
    },
    beforeMount() {
      this.changeProfilePicture(this.$auth.user().profilePicture)
        if(this.profilePictureSrc){
          this.files.push({
            url: this.profilePictureSrc
          })
        }
    },

    watch: {
      edit(value) {
        if (value) {
          if(this.user.socialType && !this.user.hasAccountProfilePicture){
            this.showmsg({ text: `Se alterar a imagem de perfil, não será mais carregada com o ${this.user.socialType}!`, type: "warning" });
          }

          this.$nextTick(function () {
            if (!this.$refs.editImage) {
              return
            }
            let cropper = new Cropper(this.$refs.editImage, {
              aspectRatio: 1 / 1,
              viewMode: 1,
            })
            this.cropper = cropper
          })
        } else {
          if (this.cropper) {
            this.cropper.destroy()
            this.cropper = false
          }
        }
      }
    },
    computed: {
      ...mapGetters(["getPicture"]),
        user(){
          return this.$auth.user();
        }
    },
    methods: {
      ...mapMutations(["showmsg", "loading"]),
      ...mapActions(["changeProfilePicture"]),
      editSave() {
        this.edit = false

        let oldFile = this.files[0]

        let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
        let arr = new Uint8Array(binStr.length)
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i)
        }

        let file = new File([arr], oldFile.name, { type: oldFile.type })

        this.$refs.upload.update(oldFile.id, {
          file,
          type: file.type,
          size: file.size,
          active: true,
        })
      },

      inputFile(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          this.$nextTick(function () {
            this.edit = true
          })
        }
        if (!newFile && oldFile) {
          this.edit = false
        }
      },

      inputFilter(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
            this.showmsg({ text: "Sua escolha não é uma imagem!", type: "error" });
            return prevent()
          }
        }

        if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
          newFile.url = ''
          let URL = window.URL || window.webkitURL
          if (URL && URL.createObjectURL) {
            newFile.url = URL.createObjectURL(newFile.file)
          }
        }
      },

      cancel(){
        this.$refs.upload.clear();
        if(this.profilePictureSrc){
          this.files.push({
            url: this.profilePictureSrc
          })
        }
      },

      async customAction(file, component) {
        var reader = new FileReader();
        var thizz = this;

        reader.onload = e => {
          thizz.uploadProfilePicture(e.target.result);
        };
        reader.readAsDataURL(file.file);
      },

      uploadProfilePicture(data) {
        const imgContent = data.split(",")[1];

        this.loading(true);

        updateProfilePicture($auth.user().tokenSecurityService, imgContent)
          .then(response => {
            if (response.data === true) {
              this.changeProfilePicture(`data:image;base64,${imgContent}`);
              // window.location.reload()
              // this.showmsg({ text: "Imagem atualizada!", type: "success" });
            } else {
              this.showmsg({ text: "Erro ao alterar imagem!", type: "error" });
            }
          })
          .catch(() =>
            this.showmsg({ text: "Erro ao alterar imagem!", type: "error" })
          ).finally(() => {
            this.loading(false);
          });
      },
    }
  }
</script>

<style>
  .upload-page {
    height: 100vh !important;
    width: 100vw !important;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 200 !important;
  }

  .att-btn {
    background: var(--v-primary) !important;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
    border-radius: 20px !important;
    text-transform: initial !important;
    width: 40vw !important;
    height: 64px !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 27px !important;
    text-align: center !important;
  }

  .cancel-btn {
    background: #F4F8FF !important;
    background-blend-mode: multiply !important;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
    border-radius: 20px !important;
    color: var(--v-primary) !important;
    text-transform: initial !important;
    width: 40vw !important;
    height: 64px !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 25px !important;
    text-align: center !important;
  }

  .avatar-upload-component .drop-active {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
    text-align: center;
  }

  .avatar-upload-component .drop-active h3 {
    margin: -.5em 0 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 40px;
    color: #fff;
    padding: 0;
  }

  .edit-profile-picture {
    position: absolute;
    top: 40px;
    right: -7px;
    border-radius: 50%;
    height: 20px !important;
    width: 20px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: var(--v-primary-base);
    box-shadow: 0 0 8px 3px #B8B8B8;
    font-size: 10px;
  }

</style>
